import React from 'react';
import { graphql } from 'gatsby';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import PageHeader from 'src/components/content/PageHeader';
import PageTitle from 'src/components/content/PageTitle';
import DownloadLink from 'src/components/content/DownloadLink';

const DownloadsPage = ({ data, pageContext, location }) => {
  const {
    craft: { assets },
  } = data;

  return (
    <>
      <PageHeader />
      <Container maxWidth="lg">
        <Box pt={16} pb={8} textAlign="center">
          <PageTitle>{pageContext.title}</PageTitle>
        </Box>
        <Box pb={[4, 8, 16]}>
          {assets.map(asset => (
            <DownloadLink key={asset.id} asset={asset} />
          ))}
        </Box>
      </Container>
    </>
  );
};

export default DownloadsPage;

export const query = graphql`
  query DownloadsQuery($locale: String) {
    craft {
      assets(
        site: [$locale]
        orderBy: "dateCreated DESC"
        volume: "downloads"
      ) {
        ...assetData
      }
    }
  }
`;
