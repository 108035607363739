import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from 'src/components/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DownloadIcon from 'src/components/icons/DownloadIcon';

import bytesToSize from 'src/lib/bytesToSize';

const useStyles = makeStyles(theme => ({
  wrapper: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  title: {
    textTransform: 'none',
  },
  details: {
    textTransform: 'uppercase',
  },
  downloadLink: {
    display: 'inline-flex',
    paddingLeft: theme.spacing(2),
  },
}));

const DownloadLink = ({ asset }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={[2, 4]}
      className={classes.wrapper}
    >
      <Box>
        <Typography className={classes.title} variant="subtitle1">
          {asset.title}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" className={classes.details}>
        <Typography>
          {asset.kind} | {bytesToSize(asset.size)}
        </Typography>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={asset.url}
          className={classes.downloadLink}
          download={asset.filename}
        >
          <DownloadIcon color="primary" />
        </a>
      </Box>
    </Box>
  );
};

export default DownloadLink;
