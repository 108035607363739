import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DownloadIcon(props) {
  return (
    <SvgIcon {...props}>
      <g>
        <polygon points="17.2,11.6 13.7,15.2 13.7,1 11.7,1 11.7,15.2 8.1,11.6 6.7,13 12.7,19 18.6,13 	" />
        <g>
          <rect x="5" y="20.5" width="15" height="2" />
        </g>
      </g>
    </SvgIcon>
  );
}
